import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
   state,
   mutations,
   actions,
   getters
}