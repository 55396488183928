<template>
    <div class="my-4 text-center">
        <img src="@/assets/imgs/404.png" alt="404">
    </div>
</template>


<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css');
</style>

<script>

export default {
    
}
</script>