const state = {
    items: {
        data: []
    },
    companySelected: {
        name: '',
        products: {
            data:[]

        }


    },

    categoriesCompanySelected: {
        data:[]

    }

  

}

export default state